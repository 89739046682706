:root {

}
body {
  margin: 0;
  font-family: "lato", "arial", sans-serif !important;
  --primary-color: #4f008c;
  --primary-color-rgb: 46, 115, 185;
  --border-radius: 6px;
  // BLUE COLORS
  --blue-0: #fbfcfe;
  --blue-50: #eff7ff;
  --blue-100: #dbefff;
  --blue-200: #afd0f2;
  --blue-400: #2e73b9;
  --blue-600: #425191;
  // GRAY COLORS
  --gray-50: #cccccc !important;
  --gray-100: #dadada;
  --gray-400: #bdbdbd;
  // BLACK COLORS
  --black-300: #333333;
  background-color: var(--primary-0) !important;
  --shadow-box: 0px 5px 15px rgba(204, 204, 204, 0.15) !important;
  color: #333333 !important;
}

/* global styling for sticky footer*/
html, body, app-root {
  height: 100%;
}

.p-component {
  font-family: "lato", "arial", sans-serif !important;
}

.vn-btn {
  width: 220px;
}

.vn-letter {
  letter-spacing: 0.2em;
}
.p-toolbar {
  background-color: var(--primary-0) !important;
  border: none !important;
}

input::placeholder {
  color: var(--gray-50);
}

.p-avatar.p-avatar-lg {
  height: 44px !important;
  width: 44px !important;
}

.vn-tabs {
  .p-tabmenu-nav {
    overflow-x: hidden;
    overflow-y: auto;
    flex-direction: column !important;
    height: 100vh !important;
    width: 112px !important;
    background: white !important;
    border: 1px solid var(--primary-50) !important;
  }
  .p-menuitem-link {
    border: 0 !important;
    padding: 0.5rem !important;
    margin: 0.5rem 1rem !important;
    border-radius: var(--border-radius) !important;
  }

  :not(.p-highlight) .p-menuitem-link {
    background: unset !important;
  }

  .p-highlight .p-menuitem-link {
    background-color: var(--primary-50) !important;
  }

  .p-disabled {
    opacity: 1 !important;
  }

  .p-menuitem-link:not(.p-disabled):focus {
    background: var(--primary-50) !important;
    box-shadow: none !important;
    border: 0 !important;
  }
}

.map-container {
  width: 100%;
  height: 100%;
}

.shadow-1 {
  box-shadow: var(--shadow-box) !important;
}

.vn-card {
  box-shadow: var(--shadow-box);
  width: 322px;
}

.p-selectbutton .p-button {
  border: 0 !important;
  color: #999999;
  background: white;
  box-shadow: var(--shadow-box);
  font-size: 14px;
  min-width: 68px;
  height: 40px;
  &:first-child {
    border-left: var(--border-radius);
  }
  &:last-child {
    border-right: var(--border-radius);
  }
  &.p-highlight {
    background-color: var(--primary-600) !important;
    color: white;
  }
}

.edit-icon {
  background: url("/assets/icons/ahoy/edit.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
}

.check-box-icon {
  background: url("/assets/icons/ahoy/check-box.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
}

.cube-box-icon {
  background: url("/assets/icons/ahoy/cube-box.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
}

.x-box-icon {
  background: url("/assets/icons/ahoy/x-box.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
}

.p-dialog:not(.p-dialog-custom-padding) .p-dialog-header {
  padding: 1.5rem 2.5rem !important;
}

.m-select {
  min-width: 180px;
  .p-multiselect-panel {
    left: -52px !important;
  }
}

.p-scrolltop-sticky {
  position: fixed !important;
}

.p-scrolltop-icon {
  font-size: 1rem !important;
}

.p-scrolltop {
  height: 2rem !important;
  width: 2rem !important;
}

.sf-select-btn {
  div[role="button"] {
    box-shadow: none !important;
    margin: 5px;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    color: black !important;
  }
  .p-button.p-highlight {
    background-color: var(--primary-100) !important;
    font-weight: bold;
  }
}

.footer-margin {
  margin-bottom: 100px;
}

.sm-dropdown {
  width: 111px;
}

.vn-input {
  height: 44px;
  border: 1px solid var(--gray-100) !important;
  .p-inputtext,
  .p-input-placeholder,
  .p-multiselect-label-container {
    margin: auto;
  }
}

.p-dialog-header-icons {
  button {
    background-color: var(--gray-100) !important;
    color: white !important;
  }
  .p-dialog-header-close-icon {
    font-size: small !important;
  }
}

.b-status {
  &#online {
    color: #18a740;
  }

  &#offline {
    color: gray;
  }

  text-transform: capitalize;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #18a740 !important;
}

.p-inline-message.p-inline-message-warn {
  background-color: rgba(223, 140, 39, 0.2) !important;
  color: #df8c27 !important;
  padding: 16px !important;
  .p-inline-message-icon {
    color: #df8c27 !important;
  }
}

.custom-calendar {
  button {
    background: transparent !important;
    border: 0 !important;
    color: black !important;
    width: unset !important;
  }
}
.custom-calendar-input {
  background: transparent !important;
  border: 0 !important;
  padding: 0 !important;
}

.p-datepicker-trigger {
  background-color: var(--primary-400) !important;
}

.ng-touched.ng-invalid:not(.no-validation),
.ng-touched.ng-invalid > *:not(.no-validation),
.ng-dirty.ng-invalid:not(.no-validation),
.ng-dirty.ng-invalid > *:not(.no-validation),
.p-inputwrapper.ng-touched.ng-invalid input:not(.no-validation),
.p-inputwrapper.ng-dirty.ng-invalid input:not(.no-validation),
app-file-uploader.ng-touched.ng-invalid .p-fileupload-buttonbar,
app-file-uploader.ng-touched.ng-invalid .p-fileupload-content {
  border-color: #f44336 !important;
}

.required:after {
  content: " *";
  color: red;
}

.multi-col-list {
  padding: 0 !important;
  list-style-type: none !important;
  li {
    margin: 8px;
  }
}

.p-menu.p-menu-overlay {
  box-shadow: 0px 8px 16px 2px rgb(97 97 97 / 10%) !important;
  border-radius: 12px;
  .p-menuitem-link:not(.p-disabled):hover {
    background-color: var(--primary-100) !important;
    border-radius: 8px;
  }
  .p-menuitem-icon,
  .p-menuitem-text {
    color: black !important;
  }
  .button-danger {
    .p-menuitem-icon,
    .p-menuitem-text {
      color: red !important;
    }
  }
  .p-menu-list {
    padding: 16px;
  }
}

.overlay {
  background: var(--maskbg);
  box-shadow: 0 0 10px 0 #ccc;
}

.searchable-ctn {
  max-height: 75vh;
  min-height: 63vh;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

:hover::-webkit-scrollbar-thumb {
  border-radius: var(--border-radius);
  background-color: var(--primary-100);
}

.pi.pi-search {
  width: fit-content;
}

.vn-date-range {
  min-width: 250px;
}

.p-checkbox {
  width: 25px !important;
  height: 25px !important;
  .p-checkbox-box {
    //border-radius: 50% !important;
    //width: 25px !important;
    //height: 25px !important;
  }
  .p-checkbox-box.p-highlight {
    border-color: #6bb40e !important;
    background: #6bb40e !important;
  }
}

.header-btns-lg {
  display: block;
}

.header-btns-sm {
  display: none;
}

.p-dialog {
  min-width: 400px !important;
  @media screen and (width < 400px) {
    min-width: calc(100% - 24px) !important;
  }
}

.text-dropdown {
  max-width: 185px;
  .p-dropdown-trigger,
  .p-dropdown-label {
    color: var(--primary-400);
    font-weight: bold;
  }
}

.p-galleria-thumbnail-item {
  flex: 1 !important;
}

.p-items-hidden .p-galleria-thumbnail-item {
  visibility: visible !important;
}

.p-galleria-item {
  margin-bottom: 16px;
  img {
    max-width: 80vw;
    max-height: 70vh;
  }
}

.p-galleria-thumbnail-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.p-galleria-header {
  position: fixed;
  top: 0;
  left: 16px;
}

.gap-3 {
  gap: 16px;
}

.date-ctn {
  min-width: 250px;
}


.w-22rem {
  max-width: 90vw;
}

.icons-popup{
  margin-left: -12px;
  --overlayArrowLeft: 0px !important;
}

@media (max-width: 767px) {
  .xs\:hide {
    display: none !important;
  }
  .xs\:col-12 {
    width: 100% !important;
  }
  .xs\:col-6 {
    width: 50% !important;
  }
  .xs\:w-auto {
    width: auto !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sm\:hide {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .md\:hide {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .lg\:hide {
    display: none !important;
  }
}

.H_imprint {
  display: none;
}



/* RTL Support */
html[dir="rtl"] {
  direction: rtl;
  text-align: right;
}

html[dir="rtl"] .p-component {
  direction: rtl;
}

/* PrimeNG RTL adjustments */
html[dir="rtl"] .p-menu-overlay,
html[dir="rtl"] .p-dialog-content,
html[dir="rtl"] .p-dropdown-panel {
  text-align: right;
}

html[dir="rtl"] .p-dropdown .p-dropdown-trigger {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* Adjust margins and paddings */
html[dir="rtl"] .p-ml-2 {
  margin-right: 0.5rem !important;
  margin-left: 0 !important;
}

html[dir="rtl"] .p-mr-2 {
  margin-left: 0.5rem !important;
  margin-right: 0 !important;
}

html[dir="rtl"] .right-0 {
  left: 0 !important;
  right: auto !important;
}