@font-face {
  font-family: 'lato';
  src: url(assets/fonts/Lato/Lato-Regular.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: 'lato';
  src: url(assets/fonts/Lato/Lato-Bold.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: 'rubik';
  src: url(assets/fonts/Rubik/Rubik-Regular.ttf) format("truetype");
}

